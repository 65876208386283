@import url('https://fonts.googleapis.com/css?family=Oswald|Roboto+Condensed');

/*
font-family: 'Roboto Condensed', sans-serif;
font-family: 'Oswald', sans-serif;
*/

body {
    background-color:#020506;
    padding: 0;
    margin: 0;
}

h1, h2, p, ul, li {
    font-family: 'Roboto Condensed', sans-serif;
}

header {
    background:#020506;
    padding:2em;
    text-align:center;
}

ul.header {
    padding: 0; 
    display:flex;
    flex-direction:row;
    justify-content: space-around;
    background: rgb(2,5,6);
    background: -moz-linear-gradient(0deg, rgba(2,5,6,1) 0%, rgba(59,48,48,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(2,5,6,1) 0%, rgba(59,48,48,1) 100%);
    background: linear-gradient(0deg, rgba(2,5,6,1) 0%, rgba(59,48,48,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020506",endColorstr="#3b3030",GradientType=1);
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    margin-bottom:0;
    list-style-type:none;
}

ul.header li a {
    color: #FFF;
    font-weight: bold;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
}

.content {
    background-color: #FFF;
    padding: 20px;
    min-height:50vh;
}


a {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

    a.active {
        background-color: #000;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }


img.logo {
    float:none;
    width:40%;
    margin:1em auto;
    display:block;
}
 