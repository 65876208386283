.timer {
  margin: 2em auto;
  width: 300px;
  height: 300px;
  background-color: #DD4814;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

h1 {
  font-size: 4em;
}

button {
  background-color: white;
  color: black;
  border: none;
  border-radius: 0.4em;
  margin: 0 1em;
  padding: 0.5em;
  display: inline-block;
}